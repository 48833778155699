import React from "react"
import { css } from "@emotion/core"
import { a, useSpring } from "@react-spring/web"

export default function BackCard({ cardData, isFliped, isAssembled }) {
  const { top, left, moveX, moveY, delay, showBackCard } = cardData

  const flipCard = useSpring({
    to: { rotateY: isFliped ? 180 : 0 },
    config: { mass: 2, tension: 400, friction: 20 },
  })

  const assembleCard = useSpring({
    to: { x: isAssembled ? moveX : 0, y: isAssembled ? moveY : 0 },
    config: { mass: 1, tension: 400, friction: 50 },
    delay: delay,
  })

  return (
    <a.div
      css={back__card(showBackCard, top, left)}
      style={{ ...flipCard, ...assembleCard }}
    />
  )
}

const back__card = (showBackCard, top, left) => css`
  position: absolute;
  top: ${top};
  left: ${left};
  width: 160px;
  height: 210px;
  border-radius: 20px;
  display: ${showBackCard ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(229deg, #fff, #efeeee);
  backface-visibility: hidden;
  color: #af6f46;
  font-size: 50px;
  font-weight: bold;
  box-shadow: -10px 10px 15px 0 rgba(87, 45, 13, 0.15),
    10px -10px 20px 0 rgba(255, 255, 255, 0.6);
  z-index: 4;
`
