import React from "react"
import { css } from "@emotion/core"
import exitIcon from "../../../images/ico_exit.png"

export default function EndButton({ setGameStart, gameResult }) {
  function shutDown() {
    setTimeout(() => {
      setGameStart(false)
    }, 1000)
  }

  return (
    <button
      css={end__btn}
      onClick={() => {
        shutDown()
        gameResult("게임 취소")
      }}
    >
      <img css={end__img} alt="endImg" src={exitIcon} />
      <div css={end__msg}>그만하기</div>
    </button>
  )
}

const end__btn = css`
  position: absolute;
  top: 500px;
  right: 280px;
  width: 162px;
  height: 162px;
  border: 1px solid #ccbbdd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle at 128% -65%, #bb7f4c, #864825);
  white-space: pre-line;
  box-shadow: 0 12.5px 20px -10px rgba(50, 50, 73, 0.2),
    0 10px 10px -10px rgba(50, 50, 73, 0.15);
  &:focus {
    outline: none;
  }
`

const end__img = css`
  position: absolute;
  top: 50px;
  left: 60px;
  width: 50px;
`

const end__msg = css`
  margin-top: 60px;
  color: #fff;
  font-size: 20px;
`
