import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { a, useSpring, useTransition } from "@react-spring/web"

const countNum = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
export default function CountDown({ setGameStart, gameResult }) {
  const [index, setIndex] = useState(0)

  const countTransition = useTransition(index, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 500 },
  })

  useEffect(() => {
    let count = 10
    let interval = 0
    function countDown() {
      interval = setInterval(changeIndex, 1000)
    }
    function changeIndex() {
      count = count - 1
      if (count !== 0) {
        setIndex(index => (index + 1) % countNum.length)
      } else if (count === 0) {
        clearInterval(interval)
      }
    }
    countDown()
  }, [])

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: 500 },
    config: {
      duration: 9000,
    },
  })

  // 카운트 완료 시 게임 종료
  useEffect(() => {
    function shutDown() {
      if (index === 9) {
        gameResult("게임 취소")
        setTimeout(() => {
          setGameStart(false)
        }, 1000)
      }
    }
    setTimeout(shutDown, 1000)
  }, [index])

  return (
    <div css={wrapper}>
      <a.div css={count__number}>
        {countTransition((style, i) => (
          <div css={count__msg} style={style}>
            {countNum[i]}
          </div>
        ))}
        <div css={end__msg}>게임종료</div>
      </a.div>
      <a.svg
        css={svg}
        width="162"
        height="162"
        strokeDashoffset={x}
        style={{ position: "absolute" }}
      >
        <circle
          cx="81"
          cy="81"
          r="78"
          strokeWidth="6"
          stroke="#b4754f"
          fill="none"
          strokeDasharray="500 500"
        />
      </a.svg>
    </div>
  )
}

const wrapper = css`
  position: absolute;
  top: 270px;
  right: 440px;
`

const count__number = css`
  position: absolute;
  width: 162px;
  height: 162px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  background-image: radial-gradient(circle at 128% -65%, #6d6966, #2e2e2d 82%);
`

const svg = css`
  border-radius: 50%;
  box-shadow: -10px 10px 15px 0 rgba(87, 45, 13, 0.15),
    10px -10px 20px 0 rgba(255, 255, 255, 0.6);
`

const count__msg = css`
  margin-top: 45px;
  font-size: 50px;
`

const end__msg = css`
  margin-top: 10px;
  color: #fff;
  font-size: 20px;
`
