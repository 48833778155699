import wink from "../../../images/ico_wink.png"
import heartedEyes from "../../../images/ico_heartEyes.png"
import shocked from "../../../images/ico_shocked.png"
import merong from "../../../images/ico_merong.png"
import thug from "../../../images/ico_thug.png"
import defaultFace from "../../../images/ico_default.png"
import leftFace from "../../../images/ico_left.png"
import devil from "../../../images/ico_devil.png"

const NINE_CARDS_CONTENT = [
  { icon: wink, content: "통과" },
  { icon: heartedEyes, content: "통과" },
  { icon: shocked, content: "통과" },
  { icon: merong, content: "통과" },
  { icon: merong, content: "통과" },
  { icon: thug, content: "통과" },
  { icon: defaultFace, content: "통과" },
  { icon: leftFace, content: "통과" },
  { icon: devil, content: "당첨" },
]

const TWELVE_CARDS_CONTENT = [
  { icon: wink, content: "통과" },
  { icon: wink, content: "통과" },
  { icon: heartedEyes, content: "통과" },
  { icon: heartedEyes, content: "통과" },
  { icon: shocked, content: "통과" },
  { icon: shocked, content: "통과" },
  { icon: merong, content: "통과" },
  { icon: merong, content: "통과" },
  { icon: thug, content: "통과" },
  { icon: thug, content: "통과" },
  { icon: leftFace, content: "통과" },
  { icon: devil, content: "당첨" },
]

const NINE_CARDS_LIST = [
  {
    id: 1,
    top: "240px",
    left: "600px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 280,
    moveY: 240,
    delay: 0,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 2,
    top: "240px",
    left: "880px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 0,
    moveY: 240,
    delay: 80,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 3,
    top: "240px",
    left: "1160px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -280,
    moveY: 240,
    delay: 160,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 4,
    top: "480px",
    left: "600px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 280,
    moveY: 0,
    delay: 560,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 5,
    top: "480px",
    left: "880px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 0,
    moveY: 0,
    delay: 0,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 6,
    top: "480px",
    left: "1160px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -280,
    moveY: 0,
    delay: 240,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 7,
    top: "720px",
    left: "600px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 280,
    moveY: -240,
    delay: 480,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 8,
    top: "720px",
    left: "880px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 0,
    moveY: -240,
    delay: 400,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 9,
    top: "720px",
    left: "1160px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -280,
    moveY: -240,
    delay: 320,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
]

const TWELVE_CARDS_LIST = [
  {
    id: 1,
    top: "240px",
    left: "550px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 330,
    moveY: 240,
    delay: 0,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 2,
    top: "240px",
    left: "770px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 110,
    moveY: 240,
    delay: 50,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 3,
    top: "240px",
    left: "990px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -110,
    moveY: 240,
    delay: 100,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 4,
    top: "240px",
    left: "1210px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -330,
    moveY: 240,
    delay: 150,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 5,
    top: "480px",
    left: "550px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 330,
    moveY: 0,
    delay: 450,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 6,
    top: "480px",
    left: "770px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 110,
    moveY: 0,
    delay: 500,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 7,
    top: "480px",
    left: "990px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -110,
    moveY: 0,
    delay: 550,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 8,
    top: "480px",
    left: "1210px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -330,
    moveY: 0,
    delay: 200,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 9,
    top: "720px",
    left: "550px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 330,
    moveY: -240,
    delay: 400,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 10,
    top: "720px",
    left: "770px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: 110,
    moveY: -240,
    delay: 350,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 11,
    top: "720px",
    left: "990px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -110,
    moveY: -240,
    delay: 300,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
  {
    id: 12,
    top: "720px",
    left: "1210px",
    icon: "",
    content: "",
    isFliped: false,
    showFrontCard: false,
    showBackCard: true,
    showAnimatedCard: false,
    moveX: -330,
    moveY: -240,
    delay: 250,
    isBounce: false,
    isShake: false,
    isShine: false,
  },
]

export {
  NINE_CARDS_CONTENT,
  NINE_CARDS_LIST,
  TWELVE_CARDS_CONTENT,
  TWELVE_CARDS_LIST,
}
