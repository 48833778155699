/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react"
import { css } from "@emotion/core"
import { a, useSpring } from "@react-spring/web"
import flipSound from "../../../sounds/cardSlide4.mp3"

export default function AnimatedCard({
  cardData,
  cardNumber,
  flipWhichCard,
  countClick,
  setCountClick,
  addAnimationOnClick,
}) {
  const { top, left, showAnimatedCard, isBounce, isShake, isShine } = cardData

  const animation = useSpring({
    from:
      (isBounce && { y: 0 }) ||
      (isShake && { rotateZ: 0 }) ||
      (isShine && { boxShadow: "0 0 10px #f4e9ab" }),
    to:
      (isBounce && { y: -10 }) ||
      (isShake && [
        { rotateZ: 5 },
        { rotateZ: 0 },
        { rotateZ: -5 },
        { rotateZ: 0 },
      ]) ||
      (isShine && {
        boxShadow: "0 0 100px #f5e799",
      }),
    config:
      (isShake && { duration: 50 }) ||
      (isShine && { duration: 1000 }) ||
      (isBounce && { damping: 0, bounce: 1, frequency: 1 }),
    delay: isShine && 500,
    loop: (isShake && true) || (isShine && { reverse: true }),
  })

  const audio = new Audio(flipSound)
  const play = () => {
    audio.play()
  }

  return (
    <>
      <a.div
        css={animated__cards(showAnimatedCard, top, left)}
        style={animation}
        onClick={() => {
          flipWhichCard(cardData)
          setCountClick(countClick + 1)
          addAnimationOnClick()
          play()
        }}
      >
        {cardNumber}
      </a.div>
    </>
  )
}

const animated__cards = (showAnimatedCard, top, left) => css`
  position: absolute;
  top: ${top};
  left: ${left};
  width: 160px;
  height: 210px;
  border-radius: 20px;
  display: ${showAnimatedCard ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(229deg, #fff, #efeeee);
  backface-visibility: hidden;
  color: #af6f46;
  font-size: 50px;
  font-weight: bold;
  box-shadow: -10px 10px 15px 0 rgba(87, 45, 13, 0.15),
    10px -10px 20px 0 rgba(255, 255, 255, 0.6);
  z-index: 6;
`
