import React from "react"
import { css } from "@emotion/core"
import { a, useSpring } from "@react-spring/web"
import cardBackground from "../../../images/frontCard.png"

export default function FrontCard({ cardData, isFliped }) {
  const { top, left, icon, content, showFrontCard } = cardData

  const flipCard = useSpring({
    to: { rotateY: isFliped ? 0 : 180 },
    config: { mass: 2, tension: 400, friction: 20 },
  })

  return (
    <a.div css={front__cards(showFrontCard, top, left)} style={flipCard}>
      <img alt="icon" src={icon} />
      <span>{content}</span>
    </a.div>
  )
}

const front__cards = (showFrontCard, top, left) => css`
  position: absolute;
  top: ${top};
  left: ${left};
  width: 160px;
  height: 210px;
  border-radius: 20px;
  display: ${showFrontCard ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  background-image: url(${cardBackground});
  background-size: 100%;
  color: #fff;
  font-size: 40px;
  box-shadow: -10px 10px 15px 0 rgba(87, 45, 13, 0.15),
    10px -10px 20px 0 rgba(255, 255, 255, 0.6);
  z-index: 2;

  img {
    position: absolute;
    top: 20px;
    width: 80px;
  }

  span {
    padding-top: 100px;
  }
`
