import React, { useState } from "react"
import { css } from "@emotion/core"
import { a, useSpring } from "react-spring"
import devilIcon from "../../../images/ico_devil.png"

export default function HowToPlay({ setGameStart, gameResult }) {
  const [isClicked, setClicked] = useState(false)

  const hideAlert = useSpring({
    from: { opacity: 1 },
    to: isClicked && { opacity: 0 },
    config: { duration: 500 },
  })

  return (
    <a.div css={alert} style={hideAlert}>
      <div css={wrapper}>
        <h1 css={title}>게임방법</h1>
        <p css={description}>
          1. 화면에 펼쳐진 카드를 번갈아가면서 선택해주세요
        </p>
        <p css={description}>
          2.{" "}
          <span>
            "<img css={devil} alt="devil" src={devilIcon} /> 악마"
          </span>{" "}
          카드를 뽑은 사람이 <span>오늘 커피값 쏘기!</span>
        </p>
      </div>
      <button
        css={start__button}
        onClick={() => {
          setTimeout(() => {
            setGameStart(true)
          }, 1000)
          setClicked(true)
        }}
      >
        확인
      </button>
      <button
        css={stop__button}
        onClick={() => {
          gameResult("게임 취소")
          console.log("btnClicked")
        }}
      >
        취소
      </button>
    </a.div>
  )
}

const alert = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 270px;
  width: 1000px;
  height: 540px;
  border-radius: 30px;
  border: none;
  background-color: #ffffff;
`

const wrapper = css`
  margin-bottom: 60px;
`

const title = css`
  margin-bottom: 41px;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #333;
`

const description = css`
  padding: 20px;
  font-size: 30px;

  span:first-child {
    color: #b620e0;
    font-weight: bold;
  }

  span:last-child {
    font-weight: bold;
    text-decoration: underline;
  }
`

const devil = css`
  width: 35px;
  height: 35px;
  vertical-align: bottom;
`

const start__button = css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 320px;
  height: 100px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #b67d56;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #fff;
  z-index: 10;
  &:focus {
    outline: none;
  }
`

const stop__button = css`
  position: absolute;
  bottom: 0;
  right: 295px;
  width: 330px;
  height: 100px;
  padding-right: 25px;
  border: none;
  border-top-left-radius: 30px;
  background-color: #cfa283;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #fff;
  &:focus {
    outline: none;
  }
`
