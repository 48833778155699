import React, { useState, useEffect } from "react"
import SEO from "../../components/common/seo"
import { css } from "@emotion/core"
import * as queryString from "query-string"
import GamePage from "../../components/miniGame/cardGame/gamePage"
import HowToPlay from "../../components/miniGame/cardGame/howToPlay"
import {
  NINE_CARDS_LIST,
  NINE_CARDS_CONTENT,
  TWELVE_CARDS_LIST,
  TWELVE_CARDS_CONTENT,
} from "../../components/miniGame/cardGame/cardList"

export default function CardGame({ location }) {
  const [isGameStart, setGameStart] = useState(false)
  const [initCards, setInitCards] = useState([TWELVE_CARDS_LIST])
  const [cardContent, setCardContent] = useState([TWELVE_CARDS_CONTENT])

  useEffect(() => {
    const { itemLength } = queryString.parse(location.search)
    if (itemLength > 4) {
      setInitCards([...TWELVE_CARDS_LIST])
      setCardContent([...TWELVE_CARDS_CONTENT])
    }
    if (itemLength < 5) {
      setInitCards([...NINE_CARDS_LIST])
      setCardContent([...NINE_CARDS_CONTENT])
    } else {
      setInitCards([...TWELVE_CARDS_LIST])
      setCardContent([...TWELVE_CARDS_CONTENT])
    }
  }, [])

  const gameResult = el => {
    window.parent.postMessage(el, "*")
  }

  return (
    <>
      <SEO title="CardGame" />
      <div css={background}>
        {isGameStart ? (
          <GamePage
            initCards={initCards}
            cardContent={cardContent}
            setGameStart={setGameStart}
            gameResult={gameResult}
          />
        ) : (
          <HowToPlay setGameStart={setGameStart} gameResult={gameResult} />
        )}
      </div>
    </>
  )
}

const background = css`
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 1920px;
  height: 1080px;
  border: 2px solid #dbcbbb;
  background: linear-gradient(241deg, #f6f3f1, #eee5df);
`
