import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import { a, useSpring, useTransition } from "@react-spring/web"
import devilIcon from "../../../images/ico_devilRight.png"
import scaredIcon from "../../../images/ico_scared.png"

const countNum = [5, 4, 3, 2, 1]
export default function GameResult({ cardData, setGameStart, gameResult }) {
  const [index, setIndex] = useState(0)
  const penaltyCardNumber = cardData.map(el => {
    if (el.content === "당첨") return el.id
    else return ""
  })

  const countFive = useTransition(index, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  })

  useEffect(() => {
    let count = 5
    let interval = 0
    function changeIndex() {
      count = count - 1
      if (count !== 0) {
        setIndex(index => (index + 1) % countNum.length)
      } else if (count === 0) {
        clearInterval(interval)
      }
    }
    function countDown() {
      interval = setInterval(changeIndex, 1000)
    }
    countDown()
  }, [])

  // 카운트 완료 시 게임 종료
  useEffect(() => {
    function shutDown() {
      if (index === 4) {
        gameResult("게임 완료")
        setTimeout(() => {
          setGameStart(false)
        }, 1000)
      }
    }
    setTimeout(shutDown, 1000)
  }, [index])

  const rolling = useSpring({
    from: { opacity: 0, rotateX: 0 },
    to: { opacity: 1, rotateX: 1080 },
  })

  const bounce = useSpring({
    from: { y: -80 },
    to: { y: 0 },
    config: {
      damping: 0.2,
      bounce: 1,
      frequency: 1,
    },
  })

  return (
    <>
      <div css={background__blur} />
      <a.div css={result__box} style={rolling}>
        <div css={message}> {penaltyCardNumber}번 카드가 당첨되셨습니다! </div>
        <a.img
          style={bounce}
          css={devil__image}
          alt="devilImg"
          src={devilIcon}
        />
        <img css={scared__image} alt="scaredImg" src={scaredIcon} />
        <button
          css={go__to__payment__button}
          onClick={() => {
            setTimeout(() => {
              setGameStart(false)
            }, 1000)
            gameResult("게임 완료")
          }}
        >
          결제화면으로...{" "}
          {countFive((style, i) => (
            <span style={style}>{countNum[i]}</span>
          ))}
        </button>
      </a.div>
    </>
  )
}

const background__blur = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 30px;
  backdrop-filter: blur(4px);
  z-index: 6;
`

const result__box = css`
  position: absolute;
  width: 1920px;
  height: 580px;
  margin-top: 110px;
  justify-content: center;
  background-image: linear-gradient(223deg, #f6de7a, #e689be, #eba188);
  z-index: 10;
`

const message = css`
  margin: 55px auto;
  color: #fff;
  text-align: center;
  font-family: SpoqaHanSans;
  font-weight: bold;
  font-size: 50px;
  text-shadow: 3px 3px rgba(87, 45, 13, 0.15);
`

const devil__image = css`
  position: absolute;
  left: 680px;
  width: 320px;
`

const scared__image = css`
  position: absolute;
  left: 1020px;
  bottom: 100px;
  width: 200px;
`

const go__to__payment__button = css`
  position: absolute;
  left: 680px;
  bottom: -70px;
  width: 560px;
  height: 135px;
  padding: 38px 124px;
  border: none;
  border-radius: 20px;
  background-color: black;
  color: #fff;
  font-size: 40px;
  text-shadow: 3px 3px rgba(87, 45, 13, 0.15);
  &:focus {
    outline: none;
  }
`
