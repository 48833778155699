import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import _ from "lodash"
import { a, useSpring } from "react-spring"
import ReactHowler from "react-howler"
import FrontCard from "./frontCard"
import BackCard from "./backCard"
import AnimatedCard from "./animatedCard"
import EndButton from "./endButton"
import CountDown from "./countDown"
import Result from "./result"
import bgMusic from "../../../sounds/ticking.mp3"
import loose from "../../../sounds/loo.mp3"

export default function GamePage({
  initCards,
  cardContent,
  setGameStart,
  gameResult,
}) {
  const [cards, setCards] = useState([...initCards])
  const [isAssembled, setAssembled] = useState(false)
  const [isCountDown, setCountDown] = useState(false)
  const [isChoiceStart, setChoiceStart] = useState(false)
  const [countClick, setCountClick] = useState(0)
  const [isShowResult, setShowResult] = useState(false)

  const showGame = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  })

  // 카드 섞는 함수
  async function shuffleCards(cardList, contentList) {
    const shuffleContent = _.shuffle(contentList)
    const initCardList = cardList.map((el, index) => {
      el.content = shuffleContent[index].content
      el.icon = shuffleContent[index].icon
      return { ...el }
    })
    setCards(initCardList)
  }

  // 당첨 카드 확인 함수
  async function flipPenaltyCard(cardList, keyValue) {
    await new Promise(resolve => setTimeout(resolve, 500))
    const flipPenaltyCardList = cardList.map(el => {
      if (el.content === "당첨") {
        return { ...el, isFliped: keyValue, showFrontCard: keyValue }
      } else {
        return { ...el }
      }
    })
    setCards(flipPenaltyCardList)
    await new Promise(resolve => setTimeout(resolve, 500))
  }

  // 카드 모았다가 펼치기
  async function assembleCards(cardList, contentList) {
    setAssembled(true)
    await shuffleCards(cardList, contentList)
    await new Promise(resolve => setTimeout(resolve, 1500))
    await setAssembled(false)
    await new Promise(resolve => setTimeout(resolve, 1000))
  }

  // 카드 선택 시작
  async function choiceStart(cardList) {
    setChoiceStart(true)
    const showAnimatedCardList = cardList.map(el => {
      return {
        ...el,
        showFrontCard: false,
        showBackCard: false,
        showAnimatedCard: true,
      }
    })
    setCards(showAnimatedCardList)
  }

  useEffect(() => {
    // 카드 동작 순서
    async function sequence() {
      await shuffleCards(cards, cardContent)
      await flipPenaltyCard(cards, true)
      await flipPenaltyCard(cards, false)
      await assembleCards(cards, cardContent)
      await choiceStart(cards)
    }
    sequence()
  }, [])

  useEffect(() => {
    // 제한 시간 카운트
    setCountDown(false)
    function activateCountDown() {
      if (isChoiceStart) setCountDown(true)
    }
    setTimeout(activateCountDown, 3000)
  }, [isChoiceStart, countClick])

  // 카드 선택 화면에서 카드에 애니메이션 추가
  function addAnimationOnClick() {
    const animatedCardList = cards.map(el => {
      if (
        countClick % 3 === 0 &&
        el.id === Math.ceil(Math.random() * cards.length) &&
        !isShowResult
      ) {
        el.isShake = false
        el.isShine = false
        el.isBounce = true
        return el
      } else if (
        countClick % 3 === 1 &&
        el.id === Math.ceil(Math.random() * cards.length) &&
        !isShowResult
      ) {
        el.isBounce = false
        el.isShine = false
        el.isShake = true
        return el
      } else if (
        countClick % 3 === 2 &&
        el.id === Math.ceil(Math.random() * cards.length) &&
        !isShowResult
      ) {
        el.isBounce = false
        el.isShake = false
        el.isShine = true
        return el
      } else {
        el.isBounce = false
        el.isShake = false
        el.isShine = false
      }
      return el
    })
    setTimeout(() => {
      setCards(animatedCardList)
    }, 1000)
  }

  // 어떤 카드를 뒤집는지 결정
  function flipWhichCard(e) {
    const flipedCardList = cards.map(el => {
      if (e.id === el.id) {
        el.showAnimatedCard = false
        el.showFrontCard = true
        el.showBackCard = true
        el.isFliped = true
        if (e.content === "당첨") {
          setShowResult(true)
          setChoiceStart(false)
          play()
        }
        return el
      } else {
        return el
      }
    })
    setCards(flipedCardList)
  }

  const backCardList = cards.map((card, index) => {
    return (
      <BackCard
        key={card.id}
        cardData={card}
        cardNumber={index + 1}
        isAssembled={isAssembled}
        isFliped={card.isFliped}
      />
    )
  })

  const frontCardList = cards.map(card => {
    return <FrontCard key={card.id} cardData={card} isFliped={card.isFliped} />
  })

  const animatedCardList = cards.map((card, index) => {
    return (
      <AnimatedCard
        key={card.id}
        cardData={card}
        cardNumber={index + 1}
        flipWhichCard={flipWhichCard}
        countClick={countClick}
        setCountClick={setCountClick}
        addAnimationOnClick={addAnimationOnClick}
      />
    )
  })

  const audio = new Audio(loose)
  const play = () => {
    audio.play()
  }

  return (
    <a.div css={game__container} style={showGame}>
      <span css={guide__message}>카드를 한장씩 선택해주세요</span>
      {isShowResult ? (
        <Result
          cardData={cards}
          setGameStart={setGameStart}
          gameResult={gameResult}
        />
      ) : (
        <ReactHowler src={bgMusic} playing={true} loop={true} volume={0.5} />
      )}
      <EndButton setGameStart={setGameStart} gameResult={gameResult} />
      {animatedCardList} {backCardList} {frontCardList}
      {isCountDown && !isShowResult && (
        <CountDown setGameStart={setGameStart} gameResult={gameResult} />
      )}
    </a.div>
  )
}

const game__container = css`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  width: 1920px;
  height: 960px;
`

const guide__message = css`
  font-family: SpoqaHanSans;
  font-size: 50px;
  font-weight: bold;
  color: linear-gradient(262deg, #af6f46 183%, #ffdcc0 49%);
  background: linear-gradient(262deg, #af6f46 183%, #ffdcc0 49%);
  text-shadow: 3px 3px rgba(87, 45, 13, 0.15);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
`
